import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MainLogo from "../../assets/shanikawa home logo.png"
import codeflex from "../../assets/codeflex.jpg"
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTiktok, FaLinkedin, FaYoutube, FaTwitter } from 'react-icons/fa';

class footer extends React.Component {

    render() {
        return (
            <div>
                <div id="home" className="web-view">
                    <footer>
                        <div className="hero p-4">
                            <Container className="mt-4">
                                <div className="d-flex" >
                                    <div className="text-left footer-col-size" >
                                        <h6>More Details</h6>
                                        <ul className="list-unstyled">
                                            <li><a href="/sell-fast" className="footer-link">Sell Fast</a></li>
                                            <li><a href="/membership" className="footer-link">Membership</a></li>
                                            <li><a href="/banner-ads" className="footer-link">Banner & Promotions</a></li>
                                        </ul>
                                    </div>
                                    <div className="text-left footer-col-size" >
                                        <h6>Help & Support</h6>
                                        <ul className="list-unstyled">
                                            <li><a href="/faq" className="footer-link">FAQ</a></li>
                                            <li><a href="/stay-safe" className="footer-link">Stay safe</a></li>
                                            <li><a href="/contact" className="footer-link">Contact Us</a></li>
                                        </ul>
                                    </div>
                                    <div className="text-center footer-col-size" >
                                        <h6>About</h6>
                                        <ul className="list-unstyled">
                                            <li><a href="/about-us" className="footer-link">About Us</a></li>
                                            <li><a href="/terms" className="footer-link">Terms and Conditions</a></li>
                                            <li><a href="/privacy-policy" className="footer-link">Privacy policy</a></li>
                                        </ul>
                                    </div>
                                    <div className="d-flex flex-column align-items-end footer-col-size" >
                                        <h6>Follow Us</h6>
                                        <ul className="list-unstyled">
                                            <div className="d-flex justify-content-end">
                                                <li className="p-1">
                                                    <a href="https://www.facebook.com/Shanikawa.lk.SL" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaFacebook size={28} />
                                                    </a>
                                                </li>
                                                <li className="p-1">
                                                    <a href="https://www.instagram.com/shanikawa.lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaInstagram size={28} />
                                                    </a>
                                                </li>
                                                <li className="p-1">
                                                    <a href="https://www.tiktok.com/@shanikawa.lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaTiktok size={28} />
                                                    </a>
                                                </li>
                                            </div>
                                            <div className="d-flex justify-content-end mt-2">
                                                <li className="p-1">
                                                    <a href="https://www.linkedin.com/company/shanikawa-lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaLinkedin size={28} />
                                                    </a>
                                                </li>
                                                <li className="p-1">
                                                    <a href="https://www.youtube.com/@Shanikawa_lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaYoutube size={28} />
                                                    </a>
                                                </li>
                                                <li className="p-1">
                                                    <a href="https://x.com/shanikawa_lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaTwitter size={28} />
                                                    </a>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className="d-flex flex-column align-items-end footer-col-size" >
                                        <h6>Powered By</h6>
                                        <div className="align-items-center">
                                            <a href="https://www.codeflexofficial.com" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                <img src={codeflex} alt="Company Logo" style={{ width: '80px' }} href="https://www.codeflexofficial.com" />
                                                <br />
                                                Codeflex
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className="footer text-light py-3">
                            <Container>
                                <Row className="align-items-center">
                                    <Col md={4} className="text-left">
                                        <p className="mb-0">&copy; {new Date().getFullYear()} CodeFlex. All rights reserved.</p>
                                    </Col>
                                    <Col md={2}></Col>
                                    <Col md={6} className="text-right d-flex justify-content-end align-items-center">
                                        <img src={MainLogo} alt="Shnikawa.LK" className="footer-logo mr-2" />
                                        <span className="footer-site-name">Shanikawa.LK</span>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </footer>
                </div>
                <div id="home" className="mobile-view">
                    <footer>
                        <div className="hero p-4">
                            <Container className="mt-4">
                                <Row>
                                    <Col className="text-left" md={6} xs={6}>
                                        <h6>More Details</h6>
                                        <ul className="list-unstyled">
                                            <li><a href="/sell-fast" className="footer-link">Sell Fast</a></li>
                                            <li><a href="/membership" className="footer-link">Membership</a></li>
                                            <li><a href="/banner-ads" className="footer-link">Banner & Promotions</a></li>
                                        </ul>
                                    </Col>
                                    <Col className="text-right" md={6} xs={6}>
                                        <h6>Help & Support</h6>
                                        <ul className="list-unstyled">
                                            <li><a href="/faq" className="footer-link">FAQ</a></li>
                                            <li><a href="/stay-safe" className="footer-link">Stay safe</a></li>
                                            <li><a href="/contact" className="footer-link">Contact Us</a></li>
                                        </ul>
                                    </Col>
                                    <Col className="text-left" md={6} xs={6}>
                                        <h6>About</h6>
                                        <ul className="list-unstyled">
                                            <li><a href="/about-us" className="footer-link">About Us</a></li>
                                            <li><a href="/terms" className="footer-link">Terms and Conditions</a></li>
                                            <li><a href="/privacy-policy" className="footer-link">Privacy policy</a></li>
                                        </ul>
                                    </Col>
                                    <Col className="d-flex flex-column align-items-end" md={6} xs={6}>
                                        <h6>Follow Us</h6>
                                        <ul className="list-unstyled">
                                            <div className="d-flex justify-content-end">
                                                <li className="p-1">
                                                    <a href="https://www.facebook.com/Shanikawa.lk.SL" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaFacebook size={28} />
                                                    </a>
                                                </li>
                                                <li className="p-1">
                                                    <a href="https://www.instagram.com/shanikawa.lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaInstagram size={28} />
                                                    </a>
                                                </li>
                                                <li className="p-1">
                                                    <a href="https://www.tiktok.com/@shanikawa.lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaTiktok size={28} />
                                                    </a>
                                                </li>
                                            </div>
                                            <div className="d-flex justify-content-end mt-2">
                                                <li className="p-1">
                                                    <a href="https://www.linkedin.com/company/shanikawa-lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaLinkedin size={28} />
                                                    </a>
                                                </li>
                                                <li className="p-1">
                                                    <a href="https://www.youtube.com/@Shanikawa_lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaYoutube size={28} />
                                                    </a>
                                                </li>
                                                <li className="p-1">
                                                    <a href="https://x.com/shanikawa_lk" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                        <FaTwitter size={28} />
                                                    </a>
                                                </li>
                                            </div>
                                        </ul>
                                    </Col>
                                    <Col className="d-flex flex-column align-items-center" md={12} xs={12}>
                                        <h6>Powered By</h6>
                                        <div className="align-items-center">
                                            <a href="https://www.facebook.com/CodeFlexOfficial" target="_blank" rel="noopener noreferrer" className="footer-link">
                                                <img src={codeflex} alt="Company Logo" style={{ width: '80px' }} href="https://www.facebook.com/CodeFlexOfficial" />
                                                <br />
                                                CodeFlex
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="footer text-light py-3">
                            <Container>
                                <Row className="align-items-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} CodeFlex. All rights reserved.</p>
                                </Row>
                                <br />
                                <Row className="align-items-center">
                                    <div className="d-flex justify-content-center">
                                        <img src={MainLogo} alt="Shnikawa.LK" className="footer-logo mr-2" />
                                        <span className="footer-site-name">Shanikawa.LK</span>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default footer;
